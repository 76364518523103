import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
let TrivestSearchField = class TrivestSearchField extends Vue {
    /**
     * The value of v-model will be bind to value and automatically updated
     * with the value passed to the input event
     */
    value;
    isDirty = false;
    onInput(val) {
        this.isDirty = val != null && val.toString().length > 0;
        this.$emit('input', val);
    }
};
__decorate([
    Prop({ type: String, default: '' })
], TrivestSearchField.prototype, "value", void 0);
TrivestSearchField = __decorate([
    Component
], TrivestSearchField);
export default TrivestSearchField;
