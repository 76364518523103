import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
let SkeletonEditField = class SkeletonEditField extends Vue {
};
SkeletonEditField = __decorate([
    Component({
        components: {},
    })
], SkeletonEditField);
export default SkeletonEditField;
