import { __decorate } from "tslib";
import { format, isValid, parse } from 'date-fns';
import { Component, Model, Prop, Vue, Watch } from "vue-property-decorator";
const DEFAULT_DATE = '';
const DEFAULT_TIME = '00:00:00';
const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd';
const DEFAULT_TIME_FORMAT = 'HH:mm:ss';
const DEFAULT_DIALOG_WIDTH = 340;
const DEFAULT_CLEAR_TEXT = 'CLEAR';
const DEFAULT_CANCEL_TEXT = 'CANCEL';
const DEFAULT_OK_TEXT = 'OK';
let TrivestDateTimeEdit = class TrivestDateTimeEdit extends Vue {
    name = 'TrivestDateTimePicker';
    datetime;
    disabled;
    loading;
    label;
    dialogWidth;
    dateFormat;
    timeFormat;
    clearText;
    cancelText;
    okText;
    textFieldProps;
    datePickerProps;
    timePickerProps;
    returnString;
    errorMessages;
    display = false;
    activeTab = 0;
    date = DEFAULT_DATE;
    time = DEFAULT_TIME;
    mounted() {
        this.init();
    }
    allowedMinutes(min) {
        return min % 5 == 0;
    }
    get dateTimeFormat() {
        return this.dateFormat + ' ' + this.timeFormat;
    }
    get defaultDateTimeFormat() {
        return DEFAULT_DATE_FORMAT + ' ' + DEFAULT_TIME_FORMAT;
    }
    get formattedDatetime() {
        return this.selectedDatetime ? this.$i18n.d(this.selectedDatetime, 'dateTimeShort') : '';
    }
    get selectedDatetime() {
        if (this.date && this.time) {
            let datetimeString = this.date + ' ' + this.time;
            if (this.time.length === 5) {
                datetimeString += ':00';
            }
            return parse(datetimeString, this.defaultDateTimeFormat, new Date());
        }
        else {
            return null;
        }
    }
    get returnValue() {
        if (this.returnString) {
            if (this.date && this.time)
                return this.date + 'T' + this.time;
            else
                return "";
        }
        else {
            return this.selectedDatetime;
        }
    }
    get dateSelected() {
        return !this.date;
    }
    init() {
        if (!this.datetime) {
            return;
        }
        let initDateTime = this.getInitialDate();
        if (!isValid(initDateTime))
            return;
        this.date = format(initDateTime, DEFAULT_DATE_FORMAT);
        this.time = format(initDateTime, DEFAULT_TIME_FORMAT);
    }
    getInitialDate() {
        let initDateTime = new Date();
        if (this.datetime instanceof Date) {
            initDateTime = this.datetime;
        }
        else if (this.datetime) {
            // see https://stackoverflow.com/a/9436948
            initDateTime = new Date(this.datetime);
        }
        return initDateTime;
    }
    okHandler() {
        this.resetPicker();
        this.$emit('input', this.returnValue);
    }
    clearHandler() {
        this.resetPicker();
        this.date = DEFAULT_DATE;
        this.time = DEFAULT_TIME;
        this.$emit('input', null);
    }
    cancelHandler() {
        this.resetPicker();
        let initDateTime = this.getInitialDate();
        this.date = format(initDateTime, DEFAULT_DATE_FORMAT);
        this.time = format(initDateTime, DEFAULT_TIME_FORMAT);
        this.$emit('input', this.returnValue);
    }
    resetPicker() {
        this.display = false;
        this.activeTab = 0;
        if (this.$refs.timer) {
            //Hack to reset to hour-selection
            this.$refs.timer.selectingHour = true;
        }
    }
    showTimePicker() {
        this.activeTab = 1;
    }
    onDateTimeChange(val, oldVal) {
        this.init();
    }
};
__decorate([
    Model('input', { type: [Date, String] })
], TrivestDateTimeEdit.prototype, "datetime", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], TrivestDateTimeEdit.prototype, "disabled", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], TrivestDateTimeEdit.prototype, "loading", void 0);
__decorate([
    Prop({ type: String, default: '' })
], TrivestDateTimeEdit.prototype, "label", void 0);
__decorate([
    Prop({ type: Number, default: DEFAULT_DIALOG_WIDTH })
], TrivestDateTimeEdit.prototype, "dialogWidth", void 0);
__decorate([
    Prop({ type: String, default: DEFAULT_DATE_FORMAT })
], TrivestDateTimeEdit.prototype, "dateFormat", void 0);
__decorate([
    Prop({ type: String, default: DEFAULT_TIME_FORMAT })
], TrivestDateTimeEdit.prototype, "timeFormat", void 0);
__decorate([
    Prop({ type: String, default: DEFAULT_CLEAR_TEXT })
], TrivestDateTimeEdit.prototype, "clearText", void 0);
__decorate([
    Prop({ type: String, default: DEFAULT_CANCEL_TEXT })
], TrivestDateTimeEdit.prototype, "cancelText", void 0);
__decorate([
    Prop({ type: String, default: DEFAULT_OK_TEXT })
], TrivestDateTimeEdit.prototype, "okText", void 0);
__decorate([
    Prop({ type: Object, default: undefined })
], TrivestDateTimeEdit.prototype, "textFieldProps", void 0);
__decorate([
    Prop({ type: Object, default: undefined })
], TrivestDateTimeEdit.prototype, "datePickerProps", void 0);
__decorate([
    Prop({ type: Object, default: undefined })
], TrivestDateTimeEdit.prototype, "timePickerProps", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], TrivestDateTimeEdit.prototype, "returnString", void 0);
__decorate([
    Prop({ type: Array, default: () => [] })
], TrivestDateTimeEdit.prototype, "errorMessages", void 0);
__decorate([
    Watch('datetime')
], TrivestDateTimeEdit.prototype, "onDateTimeChange", null);
TrivestDateTimeEdit = __decorate([
    Component({
        components: {},
    })
], TrivestDateTimeEdit);
export default TrivestDateTimeEdit;
