import { __decorate } from "tslib";
import { Component, Emit, PropSync, Vue } from 'vue-property-decorator';
let TrivestAppBarSearchField = class TrivestAppBarSearchField extends Vue {
    /**
     * The value of v-model will be bind to value and automatically updated
     * with the value passed to the input event
     */
    valueSynced;
    isDirty = false;
    searchClosed = true;
    get canCollapse() {
        return this.$vuetify.breakpoint.mobile;
    }
    get isSearchClosed() {
        return this.canCollapse && this.searchClosed && !this.valueSynced;
    }
    onInput(val) {
        this.isDirty = val != null && val.toString().length > 0;
        return val;
    }
    onDebouncedInput(val) {
        return val;
    }
};
__decorate([
    PropSync('value', { type: String, default: '' })
], TrivestAppBarSearchField.prototype, "valueSynced", void 0);
__decorate([
    Emit('searchQueryChanged')
], TrivestAppBarSearchField.prototype, "onDebouncedInput", null);
TrivestAppBarSearchField = __decorate([
    Component({
        components: {},
    })
], TrivestAppBarSearchField);
export default TrivestAppBarSearchField;
