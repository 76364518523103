import { __decorate } from "tslib";
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
let TrivestAutoComplete = class TrivestAutoComplete extends Vue {
    textProperty;
    subtitleProperty;
    valueProperty;
    showSubTitle;
    loading;
    selection;
    searchInput = "";
};
__decorate([
    Prop({ type: String, required: true })
], TrivestAutoComplete.prototype, "textProperty", void 0);
__decorate([
    Prop({ type: String, required: false })
], TrivestAutoComplete.prototype, "subtitleProperty", void 0);
__decorate([
    Prop({ type: String, required: true })
], TrivestAutoComplete.prototype, "valueProperty", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], TrivestAutoComplete.prototype, "showSubTitle", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], TrivestAutoComplete.prototype, "loading", void 0);
__decorate([
    PropSync('selectedItems', { type: Object, default: null })
], TrivestAutoComplete.prototype, "selection", void 0);
TrivestAutoComplete = __decorate([
    Component
], TrivestAutoComplete);
export default TrivestAutoComplete;
