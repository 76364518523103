import TrivestDateTimePicker from './TrivestDateTimePicker/TrivestDateTimePicker.vue';
import TrivestSearchField from './TrivestSearchField/TrivestSearchField.vue';
import TrivestAppBarSearchField from './TrivestAppBarSearchField/TrivestAppBarSearchField.vue';
import TrivestAutoComplete from './TrivestAutoComplete/TrivestAutoComplete.vue';
import TrivestButton from './TrivestButton/TrivestButton.vue';
import TrivestDialog from './TrivestDialog/TrivestDialog.vue';
import { TcTextField, TcAvatar, TcTextarea, TcDebouncedTextField } from '@tc/ui-component/src/components';
const install = (Vue, options) => {
    Vue.component('TrivestDateTimePicker', TrivestDateTimePicker);
    Vue.component('TrivestTextField', TcTextField);
    Vue.component('TrivestAppBarSearchField', TrivestAppBarSearchField);
    Vue.component('TrivestSearchField', TrivestSearchField);
    Vue.component('TrivestAutoComplete', TrivestAutoComplete);
    Vue.component('TrivestTextarea', TcTextarea);
    Vue.component('TrivestAvatar', TcAvatar);
    Vue.component('TrivestButton', TrivestButton);
    Vue.component('TrivestDialog', TrivestDialog);
    Vue.component('TrivestDebouncedTextField', TcDebouncedTextField);
};
export default install;
