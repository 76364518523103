class MenuItem {
    title;
    icon;
    link;
    order;
    menuTranslation;
    constructor(title, icon, link, order, menuTranslation) {
        this.title = title;
        this.icon = icon;
        this.link = link;
        this.order = order;
        this.menuTranslation = menuTranslation;
    }
}
export default MenuItem;
