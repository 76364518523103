import { __decorate } from "tslib";
import AppBar from "@/components/AppBar.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
let AppBarResponsiveButton = class AppBarResponsiveButton extends Vue {
    buttonText;
    buttonIcon;
    textBeforeIcon;
    finished;
    error;
    loading;
    get iconMargins() {
        let margins = "mr-0 pl-2";
        if (!this.$vuetify.breakpoint.mobile) {
            if (this.textBeforeIcon)
                margins += " ml-1";
            else
                margins += " mr-1";
        }
        return margins;
    }
};
__decorate([
    Prop({ type: String, default: '' })
], AppBarResponsiveButton.prototype, "buttonText", void 0);
__decorate([
    Prop({ type: String, default: '' })
], AppBarResponsiveButton.prototype, "buttonIcon", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], AppBarResponsiveButton.prototype, "textBeforeIcon", void 0);
__decorate([
    Prop({ type: Boolean, default: false, required: false })
], AppBarResponsiveButton.prototype, "finished", void 0);
__decorate([
    Prop({ type: Boolean, default: false, required: false })
], AppBarResponsiveButton.prototype, "error", void 0);
__decorate([
    Prop({ type: Boolean, required: false, default: false })
], AppBarResponsiveButton.prototype, "loading", void 0);
AppBarResponsiveButton = __decorate([
    Component({
        components: {
            AppBar
        },
    })
], AppBarResponsiveButton);
export default AppBarResponsiveButton;
